import React from 'react'
import PageApi from '../components/pages/Api/Api'
import Layout from '../Layouts/en'

const apiEn = ({ location }) => {
  return (
    <Layout location={location}>
      <PageApi />
    </Layout>
  )
}

export default apiEn
